<template>
  <div class="card p-2">
    <vue3-html2pdf
        :show-layout="true"
        :float-layout="false"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1400"
        pdf-content-width="100%"
        :htmlToPdfOptions="htmlToPdfOptions"
        ref="html2Pdf"
    >
      <template  v-slot:pdf-content>
        <div class="card-body">
          <div>
            <h1 class="text-center">
              <span v-if="company.name"> {{ company.name }} </span>
              <span v-else> Company Name </span>
            </h1>
            <h1 class="text-center">Comparative Statement Of Price Quotation</h1>
          </div>
          <section class="spec">
            <span><strong>CS Number:</strong> {{ csData.cs_number }}</span>
            <span><strong>PR No:</strong> {{ csData.requisition_number }}</span>
            <span><strong>CS Date:</strong> {{ csData.cs_date }}</span>
            <span><strong>PR Date:</strong> {{ csData.requisition_date }}</span>
          </section>
          <div class="cs-table table-responsive mt-2 pb-2">
            <table class="table table-bordered">
              <thead>
              <tr>
                <th colspan="2" class="text-center">Supplier Details</th>
                <th colspan="5" class="text-center" v-for="supplier in csData.suppliers">
                  {{ supplier.full_name }}
                </th>
              </tr>
              <tr>
                <th>Particulars</th>
                <th>Req Qty</th>
                <template v-for="supplier in csData.suppliers">
                  <th>Specification</th>
                  <th>Unit price / Amount</th>
                  <th>VAT %</th>
                  <th>VAT Amount</th>
                  <th>Total Amount</th>
                </template>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in csData.table_data">
                <td>{{ item.particular }}</td>
                <td>{{ item.qty }}</td>
                <template  v-for="supplier in csData.suppliers">
                  <td>{{ getItemData(supplier.id, 'item_specification', item.supplier_data) }}</td>
                  <td class="text-right">{{ getItemData(supplier.id, 'rate', item.supplier_data) }}</td>
                  <td class="text-right">{{ getItemData(supplier.id, 'vat', item.supplier_data) }}</td>
                  <td class="text-right">{{ getItemData(supplier.id, 'vat_amount', item.supplier_data) }}</td>
                  <td class="text-right">{{ getItemData(supplier.id, 'total_amount', item.supplier_data) }} </td>
                </template>
              </tr>

              <tr>
                <td colspan="2" class="text-center"><strong>Total</strong></td>
                <template v-for="supplier in csData.suppliers">
                  <td colspan="5" class="text-right"><strong>
                    {{ getTotalAmount(supplier.id, csData.table_data) }}
                  </strong> </td>
                </template>
              </tr>
              <tr>
                <td colspan="17">&nbsp;</td>
              </tr>
              <tr>
                <td colspan="17">*Time Required For Supply</td>
              </tr>
              <tr>
                <td colspan="17">*Payment Terms</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="mt-2 bg-black-light px-1">
            <strong>Special reason of selecting price for supplier at a higher rate</strong>
          </div>
          <div class="cs-table table-responsive mt-2">
            <table class="table table-bordered">
              <thead>
              <tr>
                <th colspan="17">C.S Committee Members</th>
              </tr>
              <tr>
                <th>Name</th>
                <th colspan="15" class="text-center">Comment</th>
                <th>Signature</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Committee member name</td>
                <td colspan="15" class="text-center">Auto comments during approval</td>
                <td>-</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
    </vue3-html2pdf>
    <vue3-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        pdf-content-width="100%"
        :htmlToPdfOptions="htmlToPdfOptions"
        ref="html2PdfPrint"
    >
      <template  v-slot:pdf-content>
        <div class="card-body">
          <div>
            <h1 class="text-center">
              <span v-if="company.name"> {{ company.name }} </span>
              <span v-else> Company Name </span>
            </h1>
            <h1 class="text-center">Comparative Statement Of Price Quotation</h1>
          </div>
          <section class="spec">
            <span><strong>CS Number:</strong> {{ csData.cs_number }}</span>
            <span><strong>PR No:</strong> {{ csData.requisition_number }}</span>
            <span><strong>CS Date:</strong> {{ csData.cs_date }}</span>
            <span><strong>PR Date:</strong> {{ csData.requisition_date }}</span>
          </section>
          <div class="cs-table table-responsive mt-2 pb-2">
            <table class="table table-bordered">
              <thead>
              <tr>
                <th colspan="2" class="text-center">Supplier Details</th>
                <th colspan="5" class="text-center" v-for="supplier in csData.suppliers">
                  {{ supplier.full_name }}
                </th>
              </tr>
              <tr>
                <th>Particulars</th>
                <th>Req Qty</th>
                <template v-for="supplier in csData.suppliers">
                  <th>Specification</th>
                  <th>Unit price</th>
                  <th>VAT %</th>
                  <th>VAT Amount</th>
                  <th>Total Amount</th>
                </template>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in csData.table_data">
                <td>{{ item.particular }}</td>
                <td>{{ item.qty }}</td>
                <template  v-for="supplier in csData.suppliers">
                  <td>{{ getItemData(supplier.id, 'item_specification', item.supplier_data) }}</td>
                  <td class="text-right">{{ getItemData(supplier.id, 'rate', item.supplier_data) }}</td>
                  <td class="text-right">{{ getItemData(supplier.id, 'vat', item.supplier_data) }}</td>
                  <td class="text-right">{{ getItemData(supplier.id, 'vat_amount', item.supplier_data) }}</td>
                  <td class="text-right">{{ getItemData(supplier.id, 'total_amount', item.supplier_data) }} </td>
                </template>
              </tr>

              <tr>
                <td colspan="2" class="text-center"><strong>Total</strong></td>
                <template v-for="supplier in csData.suppliers">
                  <td colspan="5" class="text-right"><strong>
                    {{ getTotalAmount(supplier.id, csData.table_data) }}
                  </strong> </td>
                </template>
              </tr>
              <tr>
                <td colspan="17">&nbsp;</td>
              </tr>
              <tr>
                <td colspan="17">*Time Required For Supply</td>
              </tr>
              <tr>
                <td colspan="17">*Payment Terms</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="mt-2 bg-black-light px-1">
            <strong>Special reason of selecting price for supplier at a higher rate</strong>
          </div>
          <div class="cs-table table-responsive mt-2">
            <table class="table table-bordered">
              <thead>
              <tr>
                <th colspan="17">C.S Committee Members</th>
              </tr>
              <tr>
                <th>Name</th>
                <th colspan="15" class="text-center">Comment</th>
                <th>Signature</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Committee member name</td>
                <td colspan="15" class="text-center">Auto comments during approval</td>
                <td>-</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
    </vue3-html2pdf>
    <div class="card-footer">
      <button class="btn btn-primary float-end" @click="exportToPDF">Download as PDF</button>
      <button class="btn btn-primary float-end mr-5" @click="directPrint">Print</button>
    </div>
    <Loader v-if="loader"/>
  </div>
</template>

<script setup>
import handleCompany from "@/services/modules/company";
import Loader from "@/components/atom/LoaderComponent";
import handleRequisitions from "@/services/modules/procurement/requisition";
import {onMounted, ref, inject, computed} from "vue";
import {useRoute} from "vue-router";
import Vue3Html2pdf from "vue3-html2pdf";
import figureFormatter from '@/services/utils/figureFormatter'

const company = ref({});
const loader = ref(false);
const html2Pdf = ref(null);
const html2PdfPrint = ref(null);
const {fetchCompanyInfo} = handleCompany();
const {fetchSingleCSForPrint} = handleRequisitions();
const {commaFormat, currencyToWord} = figureFormatter();
const route = useRoute();
const showError =  inject('showError');
const csData = ref({});
let requisition   = ref({})
const cs_number = computed(() => {
  return csData?.value?.cs_number;
});
const htmlToPdfOptions = ref({
  margin: 0,
  filename: cs_number,
  image: {
    type   : 'jpeg',
    quality: 1
  },
  enableLinks: false,
  html2canvas: {
    scale  : 2,
    useCORS: true
  },
  jsPDF: {
    unit       : 'mm',
    format     : 'a3',
    orientation: 'portrait'
  }
});

const exportToPDF = () => {
  html2Pdf.value.generatePdf();
}
const directPrint = () => {
  html2PdfPrint.value.generatePdf();
}
function setData(){
  csData.value.cs_date  = new Date(csData.value.cs_date).toISOString().split('T')[0];
  csData.value.requisition_date  = new Date(csData.value.requisition_date).toISOString().split('T')[0];
}
function getTotal(data, column){
  let total = 0.00;
  if (data != undefined){
    data.forEach((item)=>{
      total += parseFloat(item[column]);
    })
  }
  return total;
}
function getQuery() {
  return `?company_id=${route.params.companyId}`;
}
function getItemData(supplier_id, column, supplier_data){
  let res = '-';
  supplier_data.forEach((item)=>{
    if (supplier_id == item.contact_profile_id){
      if (['rate', 'vat_amount', 'total_amount'].includes(column)){
        res = column == 'rate' && item.is_product == '0'
              ?commaFormat(item.amount)
              :commaFormat(item[column]);
      }else if(column == 'vat'){
        res = (item[column]) + '%';
      }else{
        res = item[column];
      }
    }
  })

  return res;
}
function getTotalAmount(supplier_id, supplier_data){
  let res = 0.00;
  supplier_data.forEach((items)=>{
    items.supplier_data.forEach((item)=>{
      if (supplier_id == item.contact_profile_id){
        res += parseFloat(item.total_amount);
      }
    })
  })

  return commaFormat(res);
}

onMounted(() => {
  loader.value = true;
  const fetchCompany = fetchCompanyInfo(route.params.companyId);
  const fetchCS = fetchSingleCSForPrint(route.query.csId, getQuery());

  Promise.all([
    fetchCompany.then(res => {
      company.value = res.data;
    }),
    fetchCS.then(res => {
      csData.value = res.data;
      setData();
    })
  ])
      .then( () => loader.value = false )
      .catch( (err) => {
        showError("Something went wrong");
        loader.value = false;
      } );
})
</script>

<style scoped>
.spec {
    display: grid;
    grid-template-columns: auto auto;
    grid-row-gap: 10px;
    justify-content: space-between;
}

.cs-table th,
.cs-table td
 {
    padding: 5px 10px;
    white-space: nowrap;
}
</style>
